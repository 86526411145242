// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../public/img/bg.jpeg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".c-app[data-v-07b13bd8] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n#logo-side[data-v-07b13bd8] {\n  width: 175px;\n  height: 41px;\n}\n#logo-side .st0[data-v-07b13bd8] {\n  fill: #fff;\n}\n#logo-side .st1[data-v-07b13bd8] {\n  fill: #e1251b;\n}", ""]);
// Exports
module.exports = exports;
